<template>
  <v-container class="page-login" fill-height>
    <v-row>
      <v-col>
        <v-card class="pa-3 page-login__card" tile>
          <v-card-title>
            <img src="/static/m.png" alt="IC-IT" width="55" />
          </v-card-title>
          <v-card-text>
            <v-form
              ref="form"
              class="my-10"
              lazy-validation
              v-model="formValid"
            >
              <v-text-field
                append-icon="person"
                autocomplete="off"
                name="email"
                :label="$vuetify.lang.t('$vuetify.login.email')"
                :placeholder="$vuetify.lang.t('$vuetify.login.email')"
                type="email"
                required
                :rules="formRule.username"
                v-model="fromModel.username"
              />
              <v-text-field
                append-icon="lock"
                autocomplete="off"
                name="password"
                :label="$vuetify.lang.t('$vuetify.login.password')"
                :placeholder="$vuetify.lang.t('$vuetify.login.password')"
                type="password"
                :rules="formRule.password"
                required
                v-model="fromModel.password"
              />
            </v-form>
            <v-alert
              type="error"
              dense
              icon="warning"
              dismissible
              v-model="error"
            >
              <span>{{ $vuetify.lang.t('$vuetify.login.error') }}</span>
            </v-alert>
          </v-card-text>
          <v-card-actions>
            <!--            <router-link to="register">{{ $vuetify.lang.t('$vuetify.register.register') }}</router-link>-->
            <v-spacer />
            <v-btn large tile color="primary" @click="login" :loading="loading">
              {{ $vuetify.lang.t('$vuetify.login.submit') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'PageLogin',
  data() {
    return {
      loading: false,
      formValid: false,
      error: false,
      fromModel: {
        username: '',
        password: ''
      },
      formRule: {
        username: [
          (v) => !!v || 'Email is required.',
          (v) =>
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/.test(
              v
            ) || 'Email must be valid'
        ],
        password: [(v) => !!v || 'Password is required']
      }
    }
  },
  metaInfo() {
    return {
      title: this.$vuetify.lang.t('$vuetify.login.login')
    }
  },
  computed: {
    prefix() {
      return ''
    },
    compAuthHeader() {
      return {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('access_token')
        }
      }
    }
  },
  mounted() {
    window.addEventListener('keydown', (e) => {
      if (e.key === 'Enter') {
        this.login()
      }
    })
  },
  created() {
    if (localStorage.getItem('access_token'))
      this.$router.push({ path: '/dashboard' })
  },
  methods: {
    login() {
      if (this.$refs.form.validate()) {
        this.loading = true
        this.error = false
        let data = {
          email: this.fromModel.username,
          password: this.fromModel.password
        }

        this.$axios
          .post(this.$store.getters.getApiTokenUrl, data, {})
          .then((response) => {
            if (
              response.status === 200 &&
              typeof response.data.access_token !== 'undefined'
            ) {
              localStorage.setItem('access_token', response.data.access_token)
              document.cookie = 'access_token=' + response.data.access_token

              // set expired token time
              const now = new Date()
              localStorage.setItem(
                'expired',
                now
                  .setSeconds(now.getSeconds() + response.data.expires_in)
                  .toString()
              )

              this.init()
            } else this.error = true

            this.loading = false
          })
          .catch((error) => {
            console.log(error)
            this.error = true
            this.loading = false
          })
      }
    },
    init() {
      this.$axios
        .post(
          this.$store.getters.getApiUrl + 'api/init',
          {},
          this.compAuthHeader
        )
        .then((response) => {
          if (typeof response.data.ticket_categories !== 'undefined')
            this.$store.commit('setTicketCategories', response.data.ticket_categories)
          if (typeof response.data.ticket_statuses !== 'undefined')
            this.$store.commit('setTicketStatuses', response.data.ticket_statuses)
          if (typeof response.data.users_list !== 'undefined')
            this.$store.commit('setUsersList', response.data.users_list)
          if (typeof response.data.users !== 'undefined')
            this.$store.commit('setUsers', response.data.users)
          if (typeof response.data.auth_user !== 'undefined')
            this.$store.commit('setAuthUser', response.data.auth_user)
          if (typeof response.data.platforms !== 'undefined')
            this.$store.commit('setPlatforms', response.data.platforms)
          if (typeof response.data.projects !== 'undefined') {
            this.$store.commit('setProjects', response.data.projects)

            if (localStorage.getItem('current_project_id')) {
              let projects = this.$store.getters.getProjects
              let t = this
              let project = null

              projects.map(function (item) {
                if (localStorage.getItem('current_project_id') * 1 === item.id) {
                  t.$store.commit('setCurrentProject', item)
                  project = item
                }
              })

              if (!project) {
                this.$store.commit('setCurrentProject', response.data.projects[0])
                project = response.data.projects[0]
              }
              localStorage.setItem('current_project_id', project.id)
            } else this.$store.commit('setCurrentProject', response.data.projects[0])
          }


          this.$router.push('/dashboard')
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }
}
</script>

<style lang="sass" scoped>
.page-login
  &__card
  max-width: 600px
  margin: 0 auto
</style>
